<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px">
    <el-form-item label="名称" prop="name">
      <el-input v-model="dataForm.name" placeholder="名称"></el-input>
    </el-form-item>
    <el-form-item label="账号" prop="account">
      <el-input v-model="dataForm.account" placeholder="账号"></el-input>
    </el-form-item>
    <el-form-item label="密码" prop="password">
      <el-input v-model="dataForm.password" placeholder="密码"></el-input>
    </el-form-item>
    <el-form-item label="定制固件">
      <div v-for="(item,findex) in dingzhiFirmwares" :key="findex">
        <el-select v-model="item.deviceId" placeholder="设备" clearable @change="$set(item,'firmwareId',null)">
          <el-option v-for="(option,index) in deviceList || []"
                      :key="index" :label="option.name" :value="option.id"></el-option>
        </el-select>
        <el-select v-model="item.firmwareId" placeholder="固件" clearable>
          <el-option v-for="(option,index) in getFirmwaresByDeviceId(item.deviceId) || []"
                      :key="index" :label="option.name" :value="option.id"></el-option>
        </el-select>
        <el-button type="text" @click="remove(findex,1)">删除</el-button>
      </div>
      <el-button style="margin-top: 10px;" size="small" type="primary" @click="addFirmware(1)">添加固件</el-button>
    </el-form-item>
    <el-form-item label="我的固件">
      <div v-for="(item,findex) in wodeFirmwares" :key="findex">
        <el-select v-model="item.deviceId" placeholder="设备" clearable @change="$set(item,'firmwareId',null)">
          <el-option v-for="(option,index) in deviceList || []"
                      :key="index" :label="option.name" :value="option.id"></el-option>
        </el-select>
        <el-select v-model="item.firmwareId" placeholder="固件" clearable>
          <el-option v-for="(option,index) in getFirmwaresByDeviceId(item.deviceId) || []"
                      :key="index" :label="option.name" :value="option.id"></el-option>
        </el-select>
        <el-button type="text" @click="remove(findex,2)">删除</el-button>
      </div>
      <el-button style="margin-top: 10px;" size="small" type="primary" @click="addFirmware(2)">添加固件</el-button>
    </el-form-item>
    <el-form-item label="TCP权限">
      <el-select v-model="dataForm.tcpAuth" >
        <el-option label="启用" :value="1"></el-option>
        <el-option label="禁用" :value="0"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="权限">
      <el-checkbox v-model="dataForm.authTcp">TCP权限</el-checkbox>
      <el-checkbox v-model="dataForm.authStock">入库权限</el-checkbox>
      <el-checkbox v-model="dataForm.authNoCode">无识别码升级</el-checkbox>
    </el-form-item>
    <el-form-item label="设备配置">
      <div v-for="(item,findex) in jsons" :key="findex">
        <el-select v-model="item.deviceId" placeholder="设备" clearable @change="jsonDeviceChange(item)">
          <el-option v-for="(option,index) in deviceList || []"
                      :key="index" :label="option.name" :value="option.id"></el-option>
        </el-select>
        <el-select v-model="item.settingId" placeholder="配置" clearable @change="jsonSettingChange(item)">
          <el-option v-for="(option,index) in settingList || []"
                      :key="index" :label="option.name" :value="option.id"></el-option>
        </el-select>
        <el-button type="text" @click="removeJson(findex)">删除</el-button>
      </div>
      <el-button style="margin-top: 10px;" size="small" type="primary" @click="addJson()">添加配置</el-button>
    </el-form-item>
    
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    data () {
      return {
        visible: false,
        dataForm: {
          id: 0,
          name: '',
          account: '',
          password: '',
          lastloginTime: '',
          authTcp: false,
          authStock: false,
          authNoCode: false,
        },
        dataRule: {
          name: [
            { required: true, message: '名称不能为空', trigger: 'blur' }
          ],
          account: [
            { required: true, message: '账号不能为空', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '密码不能为空', trigger: 'blur' }
          ],
          lastloginTime: [
            { required: true, message: '最后登录时间不能为空', trigger: 'blur' }
          ]
        },
        deviceList:[],//设备集合
        firmwareList:[], //固件集合
        firmwares: [{deviceId:null,firmwareId:null,type:1}],
        settingList:[],
        jsons:[{name:null,jsonPath:null,img:null,deviceId:null,settingId:null}]
      }
    },
    computed:{
      dingzhiFirmwares(){
        return this.firmwares.filter(a=>a.type==1)
      },
      wodeFirmwares(){
        return this.firmwares.filter(a=>a.type==2)
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          this.firmwares = [{}]
          this.jsons = []
          if (this.dataForm.id) {
            this.$http({
              url: this.$http.adornUrl(`/wxs/wxscustomer/info/${this.dataForm.id}`),
              method: 'get',
              params: this.$http.adornParams()
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.name = data.wxsCustomer.name
                this.dataForm.account = data.wxsCustomer.account
                this.dataForm.password = data.wxsCustomer.password
                this.dataForm.lastloginTime = data.wxsCustomer.lastloginTime
                this.firmwares = data.wxsCustomer.firmwares
                this.jsons = data.wxsCustomer.jsons
                this.dataForm.tcpAuth = data.wxsCustomer.tcpAuth
                this.dataForm.authTcp = (data.wxsCustomer.auth||'').indexOf('tcp')>-1
                this.dataForm.authStock = (data.wxsCustomer.auth||'').indexOf('stock')>-1
                this.dataForm.authNoCode = (data.wxsCustomer.auth||'').indexOf('noCode')>-1
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.setJsonData()
            this.$http({
              url: this.$http.adornUrl(`/wxs/wxscustomer/${!this.dataForm.id ? 'save' : 'update'}`),
              method: 'post',
              data: this.$http.adornData({
                'id': this.dataForm.id || undefined,
                'name': this.dataForm.name,
                'account': this.dataForm.account,
                'password': this.dataForm.password,
                // 'lastloginTime': this.dataForm.lastloginTime,
                'firmwares': this.firmwares,
                'jsons': this.jsons,
                'tcpAuth': this.dataForm.tcpAuth,
                'auth': String([this.dataForm.authTcp?'tcp':'',this.dataForm.authStock?'stock':'',this.dataForm.authNoCode?'noCode':'']),
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        })
      },
      loadFirwareList(){
        this.$http({
          url: this.$http.adornUrl('/wxs/wxsfirmware/list'),
          method: 'get',
          params: this.$http.adornParams({
            'page': 0,
            'limit': 99999,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.firmwareList = data.page.list
          }
        })
      },
      loadDeviceList(){
        this.$http({
          url: this.$http.adornUrl('/wxs/wxsdevices/list'),
          method: 'get',
          params: this.$http.adornParams({
            'page': 0,
            'limit': 99999,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.deviceList = data.page.list
          }
        })
      },
      loadSettingList(){
        this.$http({
          url: this.$http.adornUrl('/bus/bussetting/all'),
          method: 'get',
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.settingList = data.list
          }
        })
      },
      addFirmware(type){
        this.firmwares.push({type:type})
      },
      addJson(){
        this.jsons.push({type:1})
      },
      getFirmwaresByDeviceId(deviceId){
        return this.firmwareList.filter(a => a.deviceId == deviceId)
      },
      remove(index,type){
        if(type == 1){
          this.firmwares.splice(this.firmwares.indexOf(this.dingzhiFirmwares[index]),1)
        }else{
          this.firmwares.splice(this.firmwares.indexOf(this.wodeFirmwares[index]),1)
        }
      },
      removeJson(index){
        this.jsons.splice(index,1)
      },
      jsonDeviceChange(item){
        let device = this.deviceList.find(a=>a.id == item.deviceId)
        let setting = this.settingList.find(a=>a.id == device.settingId)
        this.$set(item,'settingId',device.settingId)      
      },
      jsonSettingChange(item){
      },
      setJsonData(){
        for(let item of this.jsons){
          if(item.settingId){
            let setting = this.settingList.find(a=>a.id == item.settingId)
            if(item.deviceId){
              let device = this.deviceList.find(a=>a.id == item.deviceId)
              //item.name = device.name
              item.img = device.img
            }
            item.name = setting.name
            item.jsonPath = setting.jsonFile
          }
        }
      },
    },
    created() {
      this.loadFirwareList()
      this.loadDeviceList()
      this.loadSettingList()
    }
  }
</script>
